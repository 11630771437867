import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './modules'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0}
  }
})

router.beforeEach(async (to, from, next) => {
  next()
})

router.afterEach(() => {
  
});

export default router
