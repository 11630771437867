import { routerResolve } from '@utils'

export default [
  {
    path: '/member',
    name: 'member',
    component: resolve => routerResolve('/member', resolve)
  },
  {
    path: '/member-details',
    name: 'memberDetails',
    component: resolve => routerResolve('/member/details', resolve)
  },
]