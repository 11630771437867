<template>
  <div id="app" class="h-f">
    <Router></Router>
  </div>
</template>

<script>
import Router from '@/components/router'
export default {
  components: { Router },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
