import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: i18n.locale
  },
  getters: {
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language
    }
  },
  actions: {
    setLanguage({ commit }) {
      commit('setLanguage', i18n.locale)
    }
  },
  modules: {
  }
})
