import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLanguage } from '@utils/storage'

import zhCN from './zh-CN';
import zhHK from './zh-HK';
import en from './en';

Vue.use(VueI18n);

const messages = {
  'zh-CN': { ...zhCN },
  'zh-HK': { ...zhHK },
  'en': { ...en },
};
const i18n = new VueI18n({
  locale: getLanguage(),
  fallbackLocale: 'en',
  messages: messages,
  silentTranslationWarn: true
});

console.log(i18n.locale);

export default i18n;
