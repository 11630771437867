import { routerResolve } from '@utils'

export default [
  {
    path: '/event',
    name: 'event',
    component: resolve => routerResolve('/event', resolve)
  },
  {
    path: '/event-details',
    name: 'eventDetails',
    component: resolve => routerResolve('/event/details', resolve)
  },
]