import { routerResolve } from '@utils'

export default [
  {
    path: '/news-publication',
    name: 'newsPublication',
    component: resolve => routerResolve('/news-publication', resolve)
  },
  {
    path: '/news-publication-details',
    name: 'newsPublicationDetails',
    component: resolve => routerResolve('/news-publication/details', resolve)
  },
]