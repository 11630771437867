const children = []
const modulesFiles = require.context('./', true, /index.js$/)
modulesFiles.keys().reduce((modules, modulePath) => {
  if (modulePath === './index.js') return modules;
  const value = modulesFiles(modulePath)
  children.push(...value.default)
  return modules
}, {})

const routes = [
  { path: '/', redirect: { name: 'home' }, meta: { name: '重定向' } },
  {
    path: '/layout',
    name: 'layout',
    redirect: { name: '/home' },
    component: resolve => require([`@/views/layout`], resolve),
    children
  }
]

export default routes